import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import DashboardConstant from "@views/Pages/Dashboard/landing-image.constant";
import PopUp from "./PopUp";
import { PopUpContainer } from "./Navigate";
import ReferFriend from "./ReferFriend";
import DiscoverMore from "@views/Pages/Dashboard/Components/DiscoverMore";
import LeaderBoard from "@views/Pages/Dashboard/Components/DiscoverMore/LeaderBoard";
import LeaderBoardIcon from "@assets/img/leaderboard.gif";
import TopPlayerIcon from "@assets/img/top-players.gif";
import TopRaffles from "@assets/img/top-raffles.gif";
import MostValueIcon from "@assets/img/most-valuable.gif";
import FeatureComingSoon from "@views/Pages/Dashboard/Components/DiscoverMore/FeatureComingSoon";
import Img from "@assets/img";

export const ImgBox = styled.img`
  height: 100%;
`;

export const ThumbNailRobot = styled.img`
  display: none;
  width: fit-content;
  @media (max-width: 768px) {
    display: block;
    height: 250px;
  }
`;

export const Paragraph = styled.p<{ gradient?: boolean }>`
  width: 50%;
  line-height: 65px;
  font-size: 66px;
  font-weight: 700;
  text-align: center;
  margin-bottom: -15px;
  @media (max-width: 992px) {
    width: 100%;
    font-size: 32px;
    text-align: center;
  }
  @media (max-width: 768px) {
    font-size: 52px;
    padding: 0 40px;
    line-height: 55px;
    width: 100%;
    text-align: center;
  }
  ${(p) =>
    p.gradient
      ? "background: linear-gradient(113.96deg, #64E8DE 0%, #8A64EB 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent;"
      : ""}
`;

const WrapperParagraph = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  margin-top: 12x;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
const StyledParagraph = styled.p`
  text-wrap: no-wrap;
  margin-top: 16px;
  color: ${(props) => props.color || "black"};
  font-size: 24px;
  font-weight: 700;
  margin-left: 5px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const BannerContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 20px;
  }
`;
const BannerDesktop = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  @media (max-width: 768px) {
    display: none;
  }
`;
const BannerImg = styled.img`
  height: 60px !important;
  width: 100vw;
`;
const ContainerDashboard = styled.div`
  position: relative;
`;
export const Button = styled.button`
  background: linear-gradient(163.96deg, #c7d2fe 50%, #5650de 100%);
  color: #082f49ed;
  border: none;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-top: 20px;
  white-space: nowrap;
  border-radius: 30px;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;
const LeaderBoardSpace = styled.div`
  position: absolute;
  display: flex;
  cursor: pointer;
  right: 50px;
  z-index: 100;
  flex-direction: column;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const LogoCup = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
const RobotImgContainer = styled.div`
  display: flex;
  align-items: end;
  height: 70vh;
  padding: 20px;
  @media (max-width: 768px) {
    display: none;
  }
`;

type Props = {
  headerRef: React.RefObject<HTMLDivElement>;
};

export default function Header({ headerRef }: Props) {
  const navigate = useNavigate();
  const pageHeader = useRef<HTMLDivElement>(null);
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [leaderboardOpen, setLeaderboardOpen] = useState(false);

  useEffect(() => {
    const updateScroll = () => {
      if (window.innerWidth < 991 && pageHeader.current) {
        const windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform = `translate3d(0, ${windowScrollTop}px, 0)`;
      }
    };

    window.addEventListener("scroll", updateScroll);
    return () => {
      window.removeEventListener("scroll", updateScroll);
    };
  }, []);

  const content = () => {
    const discoverThings = leaderboardOpen
      ? [
          {
            title: "TOP REFERRER LEADERBOARD",
            iconSrc: LeaderBoardIcon,
            component: <LeaderBoard />,
            colorIcon: "#ff6440",
            bgSource: Img.BgCollapsed_1,
            bgSourceCollapsed: Img.BgCollapsedBig_1,
          },
        ]
      : [
          {
            title: "TOP REFERRER LEADERBOARD",
            iconSrc: LeaderBoardIcon,
            component: <LeaderBoard />,
            colorIcon: "#ff6440",
            bgSource: Img.BgCollapsed_1,
            bgSourceCollapsed: Img.BgCollapsedBig_1,
          },
          {
            title: "TOP RAFFLES THIS WEEK",
            iconSrc: TopRaffles,
            component: <FeatureComingSoon />,
            colorIcon: "#af6be1",
            bgSource: Img.BgCollapsed_2,
            bgSourceCollapsed: Img.BgCollapsedBig_2,
          },
          {
            title: "TOP PLAYERS THIS WEEK",
            iconSrc: TopPlayerIcon,
            component: <FeatureComingSoon />,
            colorIcon: "#52b886",
            bgSource: Img.BgCollapsed_3,
            bgSourceCollapsed: Img.BgCollapsedBig_3,
          },
          {
            title: "MOST VALUABLE BRANDS",
            iconSrc: MostValueIcon,
            component: <FeatureComingSoon />,
            colorIcon: "#ffb652",
            bgSource: Img.BgCollapsed_4,
            bgSourceCollapsed: Img.BgCollapsedBig_4,
          },
        ];
    return (
      <>
        {discoverThings.map((item, index) => (
          <DiscoverMore
            bgTitleCollapsed={item.bgSourceCollapsed}
            colorIcon={item.colorIcon}
            bgTitle={item.bgSource}
            index={index}
            key={index}
            title={item.title}
            iconSrc={item.iconSrc}
            openIndex={openIndex}
            setOpenIndex={setOpenIndex}
            setLeaderboardOpen={setLeaderboardOpen}
          >
            {item.component}
          </DiscoverMore>
        ))}
      </>
    );
  };

  return (
    <div ref={headerRef}>
      <div
        style={{
          backgroundImage: `url(${DashboardConstant.WnnrBackground})`,
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <RobotImgContainer>
          <ImgBox src={DashboardConstant.RobotDashboard} />
        </RobotImgContainer>
        <ContainerDashboard>
          <div className="motto text-center">
            <Paragraph>What are you going to win today?</Paragraph>
            <WrapperParagraph>
              <StyledParagraph color="#ea580c">FREE GAMES.</StyledParagraph>
              <StyledParagraph color="#a3e635">BIG REWARDS.</StyledParagraph>
            </WrapperParagraph>
            <WrapperParagraph>
              <ThumbNailRobot src={DashboardConstant.RobotDashboard} />
            </WrapperParagraph>
            <WrapperParagraph>
              <Button
                type="button"
                onClick={() => navigate("/dashboard")}
                className="btn-lg btn btn-primary"
              >
                Play Now!
              </Button>
            </WrapperParagraph>
            <BannerContainer>
              <BannerImg src={DashboardConstant.Banner} />
            </BannerContainer>
          </div>
        </ContainerDashboard>
        <LeaderBoardSpace>{content()}</LeaderBoardSpace>
      </div>
      <BannerDesktop>
        <img
          src={DashboardConstant.BannerDesktop}
          style={{ width: "100%", height: "15vh" }}
        />
      </BannerDesktop>
      <PopUp
        visible={visiblePopup}
        onOk={() => {
          setVisiblePopup(false);
        }}
        title={"Leader Board"}
        onCancel={() => setVisiblePopup(false)}
      >
        <PopUpContainer>
          <div style={{ padding: 20 }}>
            <LeaderBoard />
          </div>
        </PopUpContainer>
      </PopUp>
      <ReferFriend />
    </div>
  );
}
