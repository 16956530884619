import React, { useState } from "react";
import { QuestionaryPopup } from "./components/QuestionaryPopup";
import PopUp from "@components/PopUp";
import * as S from "./style";
import Img from "@assets/img";
import { Button } from "@components/Button";
import { VARIANTS } from "@components/Button/type";

enum MODAL_TYPE {
  JOIN_QUESTIONARY = "JOIN_QUESTIONARY",
  QUESTIONARY = "QUESTIONARY",
}

export const QuestionPopup = ({
  onDismiss,
  onFinish,
  raffleId,
  milestoneId,
}: {
  onDismiss: () => void;
  onFinish: () => void;
  raffleId: number;
  milestoneId?: number;
}) => {
  const [openModalByType, setOpenModalByType] = useState<MODAL_TYPE>(
    MODAL_TYPE.JOIN_QUESTIONARY
  );

  switch (openModalByType) {
    case MODAL_TYPE.JOIN_QUESTIONARY:
      return (
        <PopUp
          title="No Tickets? No worries!"
          visible={true}
          onCancel={onDismiss}
        >
          <S.PopUpContent>
            <S.JoinQuizWrapper>
              <S.JoinQuizImg src={Img.Robot} />
              <S.JoinQuizTitle>
                Answer a question and get a ticket!
              </S.JoinQuizTitle>
            </S.JoinQuizWrapper>
            <Button
              onClick={() => setOpenModalByType(MODAL_TYPE.QUESTIONARY)}
              variant={VARIANTS.PRIMARY}
              style={{
                margin: "0 auto ",
                fontSize: "20px",
                fontWeight: 500,
                padding: "10px 12px",
              }}
            >
              Join Quiz
            </Button>
          </S.PopUpContent>
        </PopUp>
      );
    default:
    case MODAL_TYPE.QUESTIONARY:
      return (
        <QuestionaryPopup
          isOpen={openModalByType === MODAL_TYPE.QUESTIONARY}
          onDismiss={onDismiss}
          onFinish={onFinish}
          raffleId={raffleId}
          milestoneId={milestoneId}
        />
      );
  }
};
