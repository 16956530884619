import React, { useState } from "react";
import ReferFriend from "./ReferFriend";
import DashboardConstant from "@views/Pages/Dashboard/landing-image.constant";
import LeaderBoardIcon from "@assets/img/leaderboard.gif";
import LeaderBoard from "@views/Pages/Dashboard/Components/DiscoverMore/LeaderBoard";
import TopPlayerIcon from "@assets/img/top-players.gif";
import TopRaffles from "@assets/img/top-raffles.gif";
import MostValueIcon from "@assets/img/most-valuable.gif";
import FeatureComingSoon from "@views/Pages/Dashboard/Components/DiscoverMore/FeatureComingSoon";
import DiscoverMore from "@views/Pages/Dashboard/Components/DiscoverMore";
import styled from "styled-components";
import Img from "@assets/img";

export const DiscoverWrapper = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
type Props = {
  discoverRef: React.RefObject<HTMLDivElement>;
};
export default function Discover({ discoverRef }: Props) {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [leaderboardOpen, setLeaderboardOpen] = useState(false);

  const content = () => {
    const discoverThings = leaderboardOpen
      ? [
          {
            title: "TOP REFERRER LEADERBOARD",
            iconSrc: LeaderBoardIcon,
            component: <LeaderBoard />,
            colorIcon: "#ff6440",
            bgSource: Img.BgCollapsed_1,
            bgSourceCollapsed: Img.BgCollapsedBig_1,
          },
        ]
      : [
          {
            title: "TOP REFERRER LEADERBOARD",
            iconSrc: LeaderBoardIcon,
            component: <LeaderBoard />,
            colorIcon: "#ff6440",
            bgSource: Img.BgCollapsed_1,
            bgSourceCollapsed: Img.BgCollapsedBig_1,
          },
          {
            title: "TOP RAFFLES THIS WEEK",
            iconSrc: TopRaffles,
            component: <FeatureComingSoon />,
            colorIcon: "#af6be1",
            bgSource: Img.BgCollapsed_2,
            bgSourceCollapsed: Img.BgCollapsedBig_2,
          },
          {
            title: "TOP PLAYERS THIS WEEK",
            iconSrc: TopPlayerIcon,
            component: <FeatureComingSoon />,
            colorIcon: "#52b886",
            bgSource: Img.BgCollapsed_3,
            bgSourceCollapsed: Img.BgCollapsedBig_3,
          },
          {
            title: "MOST VALUABLE BRANDS",
            iconSrc: MostValueIcon,
            component: <FeatureComingSoon />,
            colorIcon: "#ffb652",
            bgSource: Img.BgCollapsed_4,
            bgSourceCollapsed: Img.BgCollapsedBig_4,
          },
        ];
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {discoverThings.map((item, index) => (
          <DiscoverMore
            bgTitleCollapsed={item.bgSourceCollapsed}
            bgTitle={item.bgSource}
            colorIcon={item.colorIcon}
            index={index}
            key={index}
            title={item.title}
            iconSrc={item.iconSrc}
            openIndex={openIndex}
            setOpenIndex={setOpenIndex}
            setLeaderboardOpen={setLeaderboardOpen}
          >
            {item.component}
          </DiscoverMore>
        ))}
      </div>
    );
  };
  return (
    <DiscoverWrapper>
      <div
        style={{ backgroundImage: `url(${DashboardConstant.WnnrBackground})` }}
        className="page-header"
        data-parallax={true}
        ref={discoverRef}
      >
        {content()}
      </div>

      <ReferFriend />
    </DiscoverWrapper>
  );
}
