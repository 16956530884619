import { RootState } from "@store/store";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
`;
const Gift: React.FC = () => {
  const [searchParams] = useSearchParams();
  const isLoggedIn = useSelector((state: RootState) => state.auth.token);
  const code = searchParams.get("code");
  const navigate = useNavigate();

  useEffect(() => {
    if (code) {
      localStorage.setItem("giftCode", code);
      if (isLoggedIn) {
        navigate("/dashboard");
      } else {
        navigate("/login");
      }
    }
  }, [code]);

  return null;
};

export default Gift;
