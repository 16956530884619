import React, { useEffect, useMemo, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
// import Country from "@assets/Json/Country_and_cites.json";
import IndiaCountry from "@assets/Json/India_cities.json";
import moment from "moment";
import Select from "react-select";
// Core components
import Img from "@assets/img";
import BreadcrumbNav from "@components/BreadcrumbNav/BreadcrumbNav";
import HeaderBar from "@components/HeaderBar";
import Navigate from "@components/Navigator";
import PopUp from "@components/PopUp";
import ReferFriend from "@components/ReferFriend";
import Sidebar from "@components/SideBar";
import { Errors } from "@const/error";
import { TicketSource, TicketType } from "@const/profile";
import { updateUser } from "@store/authReducer";
import { useDispatch } from "react-redux";

//Api Call
import { getProfile, receiveTickets, updateProfile } from "@network/Profile";

/** Style */
import { toast } from "react-toastify";
import * as S from "./style";
import { ThreeCircles } from "react-loader-spinner";
import useDataSideBar from "src/hooks/useDataSideBar";
import { reverifiedEmail } from "@network/Auth/apiAuth";

interface IFormInput {
  username: string;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth?: string;
  email: string;
  phoneNumber: string;
  country: string;
  province: string;
  address: string;
  checkedNewsletter: boolean;
}

const RESEND_TIMEOUT_SECONDS = 60;

const errorMessage: Record<string, string> = {
  required: "Please input username",
  pattern: "NOT allowed characters",
};

const optionsGender = [
  {
    value: "MALE",
    label: "Male",
  },
  {
    value: "FEMALE",
    label: "Female",
  },
  {
    value: "OTHER",
    label: "Other",
  },
];

const optionsProvince = IndiaCountry.cities?.map((province) => ({
  value: province,
  label: province,
}));

const Checkbox = ({
  label,
  checked,
  onChange,
  register,
}: {
  label: string;
  checked: boolean;
  register: () => Record<string, unknown>;
  onChange: () => void;
}) => {
  console.log("register", register());

  return (
    <S.CheckboxWrapper onClick={onChange}>
      {/* <S.HiddenCheckbox {...register()} /> */}
      <S.StyledCheckbox checked={checked} />
      <S.Label>{label}</S.Label>
    </S.CheckboxWrapper>
  );
};

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const [country] = useState<string>(IndiaCountry.name);
  const [isChecked, setIsChecked] = useState(false);
  const receiveNewsLetters = useRef<boolean>(false);
  const [navbar, setNavbar] = useState(false);
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [timerLeft, setTimerLeft] = useState(0);
  const [resendEmail, setResendEmail] = useState("");
  const [profileValues, setProfileValues] = useState<any>();
  const [isFocusOnEmail, setIsFocusOnEmail] = useState(false);

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { DataSideDashboardBar } = useDataSideBar();

  const canEdit = !isDisabled && !isLoading;

  const defaultFormValues = useMemo(() => {
    if (!profileValues)
      return {
        username: "",
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        gender: "",
        email: "",
        province: "",
        address: "",
        checkedNewsletter: true,
      };
    const {
      username,
      firstName,
      lastName,
      dateOfBirth,
      gender,
      email,
      province,
      address,
    } = profileValues;
    return {
      username,
      firstName,
      lastName,
      dateOfBirth,
      gender,
      email,
      province,
      address,
      checkedNewsletter: true,
    };
  }, [profileValues]);

  const {
    setValue,
    register,
    handleSubmit,
    reset,
    watch,
    setError,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: defaultFormValues,
  });
  const watchGender = watch("gender");
  const checkedNewsletter = watch("checkedNewsletter");
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  const pastDate = new Date(
    currentDate.getFullYear() - 100,
    currentDate.getMonth(),
    currentDate.getDate()
  );
  const minDate = pastDate.toISOString().split("T")[0];
  const maxDate = currentDate.toISOString().split("T")[0];

  useEffect(() => {
    reset(defaultFormValues);
  }, [defaultFormValues, reset]);

  const isAtLeast18 = (selectedDate: string) => {
    const today = new Date();
    const birthDate = new Date(selectedDate);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      return age - 1 >= 18;
    }
    return age >= 18;
  };

  const onChangeDateOfBirth = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const min = new Date(minDate);
    const max = new Date(maxDate);
    const selectedDate = new Date(value);
    if (value.length === 10) {
      switch (true) {
        case selectedDate < min || selectedDate > max:
          setError("dateOfBirth", {
            message: "You must be at least 18 years old.",
          });
          break;
        case !isAtLeast18(value):
          setError("dateOfBirth", {
            message: "You must be at least 18 years old.",
          });
          break;
        default:
          setValue("dateOfBirth", value);
          setError("dateOfBirth", {});
          break;
      }
    }
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    console.log("data", data);

    setResendEmail("");
    setIsDisabled(false);
    if (!errors || (Object.keys(errors).length === 0 && !isDisabled)) {
      const dateOfBirth = moment(data.dateOfBirth).format("YYYY/MM/DD");
      const profileDataUpdate: any = {
        ...data,
        dateOfBirth,
      };
      try {
        setIsLoading(true);
        const updateProfileRes = await updateProfile(profileDataUpdate);
        if (updateProfileRes.status === "error") {
          toast.error(updateProfileRes.message);
          return;
        }
        if (isFocusOnEmail && updateProfileRes.data.sendMailVerifyEmail) {
          setResendEmail("Resend email");
          toast.info("Check your email to complete the verification process.");
          setIsFocusOnEmail;
        }

        if (updateProfileRes.data.receiveTicket) {
          setVisiblePopup(true);
        }

        const newData = await getProfile();

        dispatch(updateUser({ user: newData.data.profile }));
        receiveTickets({
          title: "Profile ticket",
          type: TicketType.DAILY,
          source: TicketSource.COMPLETE_PROFILE,
          expiredDate: moment().add(7, "day").toISOString(),
        }).then((data) => {
          // if (data.code === 200) setVisiblePopup(true);
        });
        toast.success("Update profile successfully");

        setIsDisabled(true);
      } catch (error: any) {
        toast.error(Errors.PROFILE_UPDATED_ERROR);
        setIsDisabled(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onReceiveNewsLetters = () => {
    setIsChecked(!isChecked);
    receiveNewsLetters.current = !receiveNewsLetters.current;
    if (receiveNewsLetters.current) {
      toast.success("You have agreed to receive newsletters via email");
    }
  };

  const onResendEmail = async () => {
    try {
      if (timerLeft > 0) return;
      setTimerLeft(RESEND_TIMEOUT_SECONDS);

      const res = await reverifiedEmail({
        email: profileValues.email,
        userId: profileValues.id,
        name: profileValues.fullName,
      });
      if (res.status === "success") {
        toast.success("Re-send email to verify successfully!");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const changeBackGround = () => {
    if (window.scrollY > 20) {
      setNavbar(true);
    } else setNavbar(false);
  };
  window.addEventListener("scroll", changeBackGround);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProfile();
        setProfileValues(response.data.profile);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (timerLeft > 0) {
      setTimeout(() => setTimerLeft(timerLeft - 1), 1000);
    }
  }, [timerLeft]);

  const HandleCancel = () => {
    setVisiblePopup(false);
  };

  return (
    <S.Wrapper>
      <Sidebar sideData={DataSideDashboardBar} />
      <S.ContainerNavigate className={navbar ? "activeBg" : ""}>
        <Navigate />
        <BreadcrumbNav />
      </S.ContainerNavigate>
      <S.ContainerWrapper>
        <HeaderBar backToDashboard={true} title="Back to Dashboard" />
        <S.Container>
          <p className="title">Profile Management</p>
          <S.FromSubmit onSubmit={handleSubmit(onSubmit)}>
            <label>User Name</label>
            <input
              disabled={!canEdit}
              {...register("username", {
                pattern:
                  /^(?!.*[\\/]).*[a-zA-Z0-9!@#$%^&*()-_=+`~{}[\]|;:'",.<>? ]*$/,
              })}
              maxLength={50}
              aria-invalid={errors.username ? "true" : "false"}
              placeholder="Input your username"
            />
            {errors.username && (
              <p role="alert" style={{ color: "red" }}>
                {errorMessage[errors.username.type]}
              </p>
            )}
            <S.ContainerInput>
              <div>
                <label>First Name</label>
                <input
                  disabled={!canEdit}
                  {...register("firstName")}
                  maxLength={50}
                  placeholder="Input your first name"
                />
                {errors.firstName && (
                  <p role="alert" style={{ color: "red" }}>
                    {errorMessage[errors.firstName.type]}
                  </p>
                )}
              </div>
              <div>
                <label>Last Name</label>
                <input
                  disabled={!canEdit}
                  {...register("lastName")}
                  maxLength={50}
                  placeholder="Input your last name"
                />
                {errors.lastName && (
                  <p role="alert" style={{ color: "red" }}>
                    {errorMessage[errors.lastName.type]}
                  </p>
                )}
              </div>
            </S.ContainerInput>
            <S.ContainerInput>
              <div>
                <label>Date of birth</label>
                <input
                  {...register("dateOfBirth")}
                  type="date"
                  name="trip-start"
                  min={minDate}
                  max={maxDate}
                  disabled={!canEdit}
                  onChange={onChangeDateOfBirth}
                />
                {errors.dateOfBirth && (
                  <p role="alert" style={{ color: "red" }}>
                    {errors.dateOfBirth.message}
                  </p>
                )}
              </div>
              <div>
                <label>Gender</label>
                <Select
                  {...register("gender")}
                  isDisabled={!canEdit}
                  className="basic-single"
                  classNamePrefix="select"
                  options={optionsGender}
                  onChange={(selectedOption) =>
                    setValue("gender", selectedOption?.value ?? "")
                  }
                  placeholder={watchGender ?? "Select your gender"}
                />
              </div>
            </S.ContainerInput>
            <S.ContainerInput>
              <S.InputWrapper>
                <div onClick={onResendEmail}>
                  {resendEmail && resendEmail}{" "}
                  {timerLeft > 0 ? `in ${timerLeft}` : ""}
                </div>
                <label>Email</label>
                <input
                  disabled={profileValues?.emailVerified || !canEdit}
                  {...register("email", {
                    pattern: /^[a-zA-Z0-9.@]+@[a-zA-Z0-9.]+\.[a-zA-Z0-9]+$/,
                  })}
                  maxLength={50}
                  placeholder="Input your email"
                  onChange={() => setIsFocusOnEmail(true)}
                />
                {errors.email && (
                  <p role="alert" style={{ color: "red" }}>
                    Invalid email address
                  </p>
                )}
              </S.InputWrapper>
              <div>
                <label>Phone Number</label>
                <input
                  className={isDisabled ? "" : "input-phone"}
                  disabled={true}
                  placeholder="Input your mobile number"
                  value={profileValues?.phoneNumber}
                />
              </div>
            </S.ContainerInput>
            <S.ContainerInput>
              <div>
                <label>Country</label>
                <Select
                  isDisabled={true}
                  className={
                    isDisabled ? "basic-single" : "basic-single disable-input"
                  }
                  classNamePrefix="select"
                  value={country}
                  placeholder={country}
                />
              </div>
              <div>
                <label>State</label>

                <Select
                  {...register("province")}
                  isDisabled={!canEdit}
                  className="basic-single"
                  classNamePrefix="select"
                  options={optionsProvince}
                  onChange={(selectedOption) =>
                    setValue("province", selectedOption?.value ?? "")
                  }
                  placeholder={profileValues?.province}
                />
              </div>
            </S.ContainerInput>
            <S.DivAddress>
              <label>Address</label>
              <input
                disabled={!canEdit}
                {...register("address")}
                maxLength={500}
                placeholder="Line 1, Line 2, City/Town"
              />
              {errors.address && (
                <p role="alert" style={{ color: "red" }}>
                  {errorMessage[errors.address.type]}
                </p>
              )}
            </S.DivAddress>
            <Checkbox
              register={() => register("checkedNewsletter")}
              label="I agree to receive newsletters via email"
              checked={isChecked}
              onChange={onReceiveNewsLetters}
            />
            <button className="btn-submit" type="submit">
              {isDisabled ? "Edit" : "Save"}
              {isLoading && (
                <div style={{ marginLeft: 20 }}>
                  <ThreeCircles
                    visible={true}
                    height={20}
                    width={20}
                    color="#fff"
                    ariaLabel="three-circles-loading"
                  />
                </div>
              )}
            </button>
          </S.FromSubmit>
        </S.Container>
      </S.ContainerWrapper>
      <ReferFriend />

      <PopUp
        visible={visiblePopup}
        title="Warning"
        footer={true}
        onCancel={() => HandleCancel()}
        onOk={() => setVisiblePopup(false)}
        okText="Continue"
        cancelText="Cancel"
      >
        <S.PopUpContainer>
          <div className="popup-title">
            Thanks for completing profile.
            <br /> You have got 1 free ticket!
          </div>
          <div>
            <img src={Img.StarIcon} alt="fire" width={132} />
          </div>
        </S.PopUpContainer>
      </PopUp>
    </S.Wrapper>
  );
};

export default Profile;
