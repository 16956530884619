import { verifyEmail } from "@network/Auth/apiAuth";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import styled from "styled-components";

export const Container = styled.div`
    min-height: 100vh;
`;
const VerifiedEmail: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      const verify = async () => {
        try {
          const res = await verifyEmail(token);
          if(res.status === 'success') {
            toast.success('Verified email is successful!')
            setTimeout(() => {
                navigate('/dashboard')
            }, 3000)
          }
          if(res.status === 'error') {
            return toast.error('Verified email is not successful!')
          }
        } catch (err: any) {    
            toast.error('Verified email is not successful!')
        }
      };
      verify();
    }
  }, [token]);

  return (
    <Container>
    <Spinner/>
    </Container>
  );
};

export default VerifiedEmail;

