import React, { useEffect, useRef, useState } from "react";
import Navigate from "@components/Navigate";
import Header from "@components/Header";
import Footer from "@components/Footer";
import Discover from "@components/Discover";

const Landing: React.FC = () => {
  const discoverRef = useRef<any>(null);
  const headerRef = useRef<any>(null);
  const [currentComponent, setCurrentComponent] = useState(1);
  const scrollTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleScroll = () => {
    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
    }

    scrollTimeout.current = setTimeout(() => {
      const scrollY = window.scrollY;
      const headerPosition = headerRef.current.offsetTop;
      const discoverPosition = discoverRef.current.offsetTop;

      if (currentComponent === 1 && scrollY >= headerPosition + 50) {
        discoverRef.current.scrollIntoView({ behavior: "smooth" });
        setCurrentComponent(2);
      }
      if (currentComponent === 2 && scrollY < discoverPosition - 170) {
        headerRef.current.scrollIntoView({ behavior: "smooth" });
        setCurrentComponent(1);
      }
    }, 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
      // nếu scrollTimeout.current tồn tại thì sẽ clear timeout đó
      // khi component unmount thì sẽ clear timeout
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentComponent]);

  return (
    <>
      <Navigate />
      <Header headerRef={headerRef} />
      <Discover discoverRef={discoverRef} />
      <Footer />
    </>
  );
};

export default Landing;
