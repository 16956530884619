import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
// Core components
import BreadcrumbNav from "@components/BreadcrumbNav/BreadcrumbNav";
import HeaderBar from "@components/HeaderBar";
import Navigate from "@components/Navigator";
import ReferFriend from "@components/ReferFriend";
import { getRaffleById, endSession } from "@network/Raffle";
import SideBar from "@components/SideBar";

import List from "./Components/List";

import {
  RaffleDetailType,
  RaffleReward,
  WinResultType,
  RaffleStatus,
} from "@type/Raffle";
import RaffleInfo from "./Components/RaffleInfo";

import { TABLE_PARTICIPANT } from "@const/participant";
import * as S from "./style";
import { EVENTS } from "@const/events";
import { connect, emit, onEvent, disconnect } from "@network/socket";
import useDataSideBar from "src/hooks/useDataSideBar";

export interface TabBar {
  id: number;
  title: string;
  content: string;
}

const tab = [
  { id: 1, title: "Raffle", content: "Content for Tab 1" },
  { id: 2, title: "Participation", content: "Content for Tab 2" },
  { id: 3, title: "Rewards List", content: "Content for Tab 3" },
];

const FavoriteRaffle: React.FC = () => {
  const [navbar, setNavbar] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [refresh, setRefresh] = useState<number>(0);
  const { id } = useParams();
  const ref = useRef(null);
  const { DataSideDashboardBar } = useDataSideBar();
  const raffleId = parseInt(id as string);

  const [activeTab, setActiveTab] = useState(1);
  const [raffle, setRaffle] = useState<RaffleDetailType>();
  const [reward, setReward] = useState<RaffleReward>();
  const [winResult, setWinResult] = useState<WinResultType | null>(null);

  const changeBackGround = () => {
    if (window.scrollY > 20) {
      setNavbar(true);
    } else setNavbar(false);
  };
  window.addEventListener("scroll", changeBackGround);

  const onSpinThePrize = (e: WinResultType) => {
    setWinResult(e);
  };

  const onRaffleCancel = () => {
    fetchRaffleById(raffleId);
  };

  const onJoinRaffle = () => {
    onEvent(EVENTS.SPIN_THE_PRIZE, onSpinThePrize);
    onEvent(EVENTS.RAFFLE_CANCELLED, onRaffleCancel);
  };

  const onConnect = () => {
    emit(EVENTS.JOIN_RAFFLE, { raffleId }, onJoinRaffle);
  };

  const fetchRaffleById = async (raffleId: number) => {
    try {
      const res = await getRaffleById(raffleId);

      setRaffle(res.data.raffle);
      setReward(res.data.currentReward);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEndSession = async (raffleId: number) => {
    try {
      await endSession(raffleId);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRaffleById(raffleId);
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }

    connect();
    onEvent(EVENTS.CONNECT, onConnect);

    return () => {
      disconnect();
      fetchEndSession(raffleId);
    };
  }, []);

  useEffect(() => {
    fetchRaffleById(raffleId);
  }, [winResult]);

  const done = () => setRefresh((prev) => prev + 1);
  const openBannerUrl = (url?: string) => {
    if (url) window.open(`${url}`, "_blank");
    return;
  };
  return (
    <S.Wrapper>
      <SideBar sideData={DataSideDashboardBar} />
      <S.ContainerNavigate className={navbar ? "activeBg" : ""}>
        <Navigate />
        <BreadcrumbNav />
        <S.TabProfile>
          <S.TabContainer>
            {tab.map((element: TabBar) => (
              <div
                key={element.id}
                onClick={() => setActiveTab(element.id)}
                className={activeTab === element.id ? "active" : ""}
              >
                <p>{element.title}</p>
              </div>
            ))}
          </S.TabContainer>
        </S.TabProfile>
      </S.ContainerNavigate>
      <S.ContainerWrapper>
        <HeaderBar isBack={true} title={raffle?.title} backlabel="Back" />
        {raffle?.bannerImg && (
          <S.BannerImg
            src={raffle?.bannerImg as string}
            onClick={() => openBannerUrl(raffle?.bannerUrl)}
          />
        )}

        <S.DetailContainer className={activeTab === 1 ? "active" : "hidden"}>
          <RaffleInfo
            listRef={ref}
            raffle={raffle}
            reward={reward}
            win={winResult}
            done={done}
            isCancel={raffle?.status === RaffleStatus.CANCEL}
          />
        </S.DetailContainer>
        <S.TableList>
          <List
            ref={ref}
            hide={isMobile && activeTab !== 2}
            title="My Played Number"
            type={TABLE_PARTICIPANT.MY_LUCKY_NUMBER}
            raffleId={raffleId}
            mainRaffleId={raffle?.raffleId}
            milestoneId={raffle?.milestoneId}
            answered={raffle?.answered as boolean}
            availableTicketToday={raffle?.availableTicketToday}
            raffleStatus={raffle?.status}
            customColor={raffle?.color}
            raffleType={raffle?.type}
            time={raffle?.startTime}
            isCancel={raffle?.status === RaffleStatus.CANCEL}
          />
          <List
            hide={isMobile && activeTab !== 2}
            title="List of participants"
            type={TABLE_PARTICIPANT.LIST_OF_PARTICIPANT}
            raffleId={raffleId}
            milestoneId={raffle?.milestoneId}
            answered={raffle?.answered as boolean}
            customColor={raffle?.color}
          />
          <List
            title="Prize List"
            hide={isMobile && activeTab !== 3}
            type={TABLE_PARTICIPANT.REWARD_LIST}
            raffleId={raffleId}
            milestoneId={raffle?.milestoneId}
            answered={raffle?.answered as boolean}
            refresh={refresh}
            customColor={raffle?.color}
          />
        </S.TableList>
      </S.ContainerWrapper>
      <ReferFriend />
    </S.Wrapper>
  );
};

export default FavoriteRaffle;
