import styled from "styled-components";
import "../../styles/constants";
import { sm } from "../../styles/constants";

export const Content = styled.div`
  width: 100%;
  padding: 20px 40px 0;
  margin-bottom: 20px;
  .note_text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: white;
    &-red {
      color: red;
    }
  }
`;

export const QuestionContainer = styled.div`
  margin-bottom: 20px;
`;

export const Question = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left !important;
  color: white;
  margin-bottom: 4px;
  flex: 1;
  word-wrap: break-word;
`;

export const AnswerGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  @media (max-width: ${sm}) {
    gap: 10px 0;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const PopUpContainer = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  .popup-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 20px 40px;
    color: #fff;
  }
`;

export const PopUpContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 425px;
`;

export const JoinQuizWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: -20px;
`;

export const JoinQuizImg = styled.img`
  display: flex;
  max-width: 150px;
`;

export const JoinQuizTitle = styled.span`
  font-size: 26px;
  font-weight: 700;
  color: white;
  margin-top: 20px;
`;

export const CongratulationTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: #abca46;
  margin-top: 20px;
`;
