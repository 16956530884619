import styled from "styled-components";

export const RadioContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-bottom: 0;

  .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin-left: 8px;
    margin-bottom: 0;
    padding: 0;
    text-transform: initial;
  }

  .hidden {
    display: none;
  }
`;
