import React from "react";
import { Row, Container, Col } from "reactstrap";
import styled from "styled-components";
import Img from "@assets/img";
import PopUp from "./PopUp";
import { PopUpContainer } from "./Navigate";
import DashboardConstant from "@views/Pages/Dashboard/landing-image.constant";

export const List = styled.ul`
  list-style: none;
  padding-left: 5px;
`;

export const Item = styled.li`
  a {
    color: #fff;
    margin: 0px 5px;
    font-weight: 500;
  }
`;

export const FooterBox = styled.footer`
  background: #090042;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const BannerWrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const TitleFooter = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 500;
`;

export const ContentFooter = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const PopupWrapper = styled.div`
  width: 660px;
  padding: 20px;
  max-height: 500px;
  overflow-y: auto;
  align-self: center;
  color: white;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .popup-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 20px 40px;
    color: #fff;
  }
`;

export const MoreInfo = styled.div``;

const Footer: React.FC = () => {
  const [visiblePopup, setVisiblePopup] = React.useState(false);
  const [showPolicyPopup, setShowPolicyPopup] = React.useState(false);
  const [showTermAndCondition, setShowTermAndCondition] = React.useState(false);
  return (
    <FooterBox className="footer footer-black footer-white">
      <Container style={{ marginTop: 45 }}>
        <Row style={{ textAlign: "left" }}>
          <Col lg="12" md="12" sm="12">
            <a href="">
              <img src={DashboardConstant.WnnrLogo} width={120} />
            </a>
          </Col>
          <Col lg="3" md="3" sm="12">
            <List>
              <Item>
                <div
                  onClick={() => {
                    setShowPolicyPopup(true);
                  }}
                  style={{
                    color: "#00ffed",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Privacy Policy
                </div>
              </Item>
              <Item>
                <div
                  onClick={() => {
                    setShowTermAndCondition(true);
                  }}
                  style={{
                    color: "#00ffed",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Terms and Conditions
                </div>
              </Item>
            </List>
          </Col>
          <Col lg="3" md="3" sm="12">
            <List>
              <Item>
                <a href="/">Share to get free ticket</a>
              </Item>
              <Item>
                <div
                  onClick={() => {
                    setVisiblePopup(true);
                  }}
                  style={{
                    color: "#00ffed",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  About Us
                </div>
              </Item>
            </List>
          </Col>
          <Col lg="3" md="3" sm="12">
            <List>
              {" "}
              <Item>
                <a href="">
                  <img src={Img.Fb} />
                </a>
                <a href="">
                  <img src={Img.X} />
                </a>
                <a href="">
                  <img src={Img.Ins} />
                </a>
                <a href="">
                  <img src={Img.In} />
                </a>
                <a href="">
                  <img src={Img.P} />
                </a>
                <a href="">
                  <img src={Img.Phone} />
                </a>
              </Item>
            </List>
          </Col>
        </Row>
      </Container>
      <PopUp
        visible={visiblePopup}
        onOk={() => {
          setVisiblePopup(false);
        }}
        title={"About Us"}
        onCancel={() => setVisiblePopup(false)}
        okText="No"
        cancelText="Yes"
      >
        <PopUpContainer>
          <div style={{ padding: "20px" }}>
            <Row>
              <div
                style={{ padding: "0 20px", textAlign: "left", color: "white" }}
              >
                WNNR, headquartered in Bangalore and owned by Two Trees Ventures
                Pvt Ltd, is a startup established in 2022 aimed at making
                digital activity rewarding. The company focuses on enhancing
                value for all stakeholders within the digital ecosystem.
                Recognizing that marketers and publishers worldwide share common
                goals of meeting audience needs and adapting to consumer
                expectations, WNNR leverages gaming services to offer insightful
                data on audience preferences. This approach promotes an
                environment characterized by reduced spam and increased
                engagement, allowing marketing and sales functions to
                concentrate on their most valuable customers. As a home-grown
                startup tailored to the Indian internet landscape, WNNR also
                extends its operations through subsidiaries in various other
                countries, reinforcing its commitment to global engagement and
                growth.
              </div>
            </Row>
            <ContentFooter
              style={{ textAlign: "left", marginLeft: "3px", color: "white" }}
            >
              <MoreInfo>
                For enquiries regarding the company,{" "}
                <a href="mailto:Info@wnnr.in">email at Info@wnnr.in</a>
              </MoreInfo>
              <MoreInfo>
                For press,
                <a href="mailto:info@wnnr.in"> info@wnnr.in</a>
              </MoreInfo>
              <MoreInfo>
                For partnerships,
                <a href="mailto:Collab@wnnr.in">email: Collab@wnnr.in</a>
              </MoreInfo>
              <MoreInfo>
                For support,
                <a href="mailto:support@wnnr.in">support@wnnr.in</a>
              </MoreInfo>
            </ContentFooter>
          </div>
        </PopUpContainer>
      </PopUp>
      <PopUp
        visible={showPolicyPopup}
        onOk={() => {
          setShowPolicyPopup(false);
        }}
        title={"Privacy Policy"}
        onCancel={() => setShowPolicyPopup(false)}
        okText="No"
        cancelText="Yes"
      >
        <PopupWrapper>
          <div style={{ textAlign: "left" }}>
            This privacy policy (hereinafter “Privacy Policy”) governs the
            collection, use, storage, disclosure, transfer or processing of
            personal information by WNNR (hereinafter “WNNR” OR “we or us”) or
            other software and application, in relation to the services offered
            by us. Any external links to other websites are clearly identifiable
            as such, and we are not responsible for the content or privacy
            policies of the other websites. We recognize the importance of the
            user (hereinafter “you”) privacy and respect that you want to
            safeguard the personal information shared with us. We make it our
            priority to ensure that your personal information remains secure and
            confidential and is used only for the intended purpose. In this
            Privacy Policy we explain our practices regarding personal
            information we collect when you use and/or access the Website. By
            accepting the terms of this Privacy Policy, you are providing your
            consent for the practices and reasons as described herein.
          </div>
          <div style={{ textAlign: "left" }}>
            General terms. This Privacy Policy describes how we collect, use,
            consult or process any personal information provided by you through
            the Website. This Privacy Policy is intended to inform you how we
            gather, define, and use the information that is provided to us when
            you access and/or use our Website. WNNR may change or amend this
            Privacy Policy from time to time to incorporate necessary future
            changes. The use of data by WNNR shall always be in adherence to
            this Privacy Policy under which the information was collected,
            regardless of new or conflicting provisions in an updated Privacy
            Policy. WNNR terms require all account owners to be at least
            eighteen (18) years of age (or have the permission and supervision
            of a responsible parent or legal guardian), therefore this Policy
            does not discuss the use of our Site by minors. Please read through
            this Privacy Policy to better understand what data we use and how we
            use your data for each of the above classifications. Collection of
            Data. Identifiable Information. When you share a post to your social
            media account, our interface will ask you to verify via an OTP. Once
            the OTP verifies you as a user, you will choose and enter your
            preferred game. After your registered contact number is verified,
            you are required to submit more personal information, including but
            not limited to your name, e-mail, location, gender, date of birth.
            This information provided by you can be termed as “Personal
            Information” as this can be used to identify you. Non-identifiable
            Information. As you use the website or Services as provided by us,
            our back-end server may automatically log the standard data provided
            by your browser. This data is considered “non-identifiable data,” as
            it does not personally identify you on its own. It may include your
            browser type, your IP address, geo-location data, etc. We also
            collect information to understand your preferences based on the
            responses provided by you for the questions asked while accessing
            our website or Services. The information we collect can depend on
            the webpage from where you can access our website, we recommend
            reading the privacy policies of the said webpage to understand what
            is the information they have access to. Use of Data. We use the
            information as provided by you for following purposes: Analyse the
            collective information provided by you to understand your
            preferences, likes and consumer behaviour; To create a database for
            purpose of analysing consumer behaviour; To analyse your interest,
            google analytics, consumer behaviour for the purpose of target and
            performance marketing, opinion polls, consumer data intelligence,
            based on target audience and profile; To offer an improved user
            experiences – we use the information (both Personal Information and
            non-identifiable data) to assist us to improve and optimize our
            Service and to personalize and customize your experience;
            Communication, marketing, and promotional activities – we may use
            information to deliver and personalize our communication with you or
            to administer rewards, surveys, contests and other promotional
            activities. Sharing User Data. We work with trusted third-party
            service providers and/or websites, wherein we provide third-party
            anonymised information for the purpose of advertisements based on
            target marketing and promotions by the particular third-party
            service providers. We share the data report or analysis with the
            publishers for the purpose of running performance campaigns, opinion
            polls based on the target audience and profile, based on the
            opinions and sentiments as gathered from you. We shall share your
            information to fulfil any obligations under any law for the time
            being to disclose any such information to the state (state herein
            refers to India). We shall share your information for compliance
            with any judgement or decree or order issued under any law or any
            judgement or order relating to claims of contractual or civil nature
            under any law for the time being in force outside India; We shall
            share your information in the event of medical emergency involving a
            threat to life or in the event of an immediate threat to life. We
            shall share your information in the event wherein it is required to
            apply appropriate measures to provide medical treatment or health
            services for you. We shall share your information with the
            authorities, as and when required, to ensure safety, or provide
            assistance or services during any disaster, or any breakdown of
            public order. Security We understand that the security of your
            information is important. We use industry standard measures to
            protect your information that is stored in our database. We follow
            industry standard best practices for information security. We limit
            the access to your personal information to those who need access to
            perform their job function and not beyond that. Rights under the
            Data Protection Laws As the User (or consumer), you are provided
            certain rights under the current data protection regime: You have
            the right to access information about your personal information as
            provided to us by you, provided the request is made in a manner as
            prescribed in the corresponding law or rules. In the event of any
            amendment or deleting or updating your information provided to us,
            you have the right to update or amend or delete the information,
            provided that the request is made in a manner as prescribed in the
            corresponding law or rules. You have the right to withdraw your
            consent from providing any further information, provided that the
            request is made in a manner as prescribed in the corresponding law
            or rules. You may make a request directing us to deleting your
            information and upon the receipt of such request we are obligated to
            act in accordance to the request, provided that the retention of
            your information is necessary for a specified purpose or compliance
            as maybe provided by the law. Contact Us If you have any queries or
            concerns regarding this Privacy Policy, you may reach us out
            directly on the following details: Email: support@wnnr.in Mailing
            Address: 96/4 Chokkanahalli Main Road, S9, Surbacon Aspen,
            Bangalore, Karnataka, 560064
          </div>
        </PopupWrapper>
      </PopUp>
      <PopUp
        visible={showTermAndCondition}
        onOk={() => {
          setShowTermAndCondition(false);
        }}
        title={"Terms And Condition"}
        onCancel={() => setShowTermAndCondition(false)}
      >
        <PopupWrapper>
          <div style={{ textAlign: "left" }}>
            <div>
              {" "}
              1. Website Description Wnnr.in is an online platform offering
              users opportunities to participate in various gaming activities
              and earn rewards. By engaging in specified digital actions, users
              can earn tickets and points which can be redeemed for various
              prizes, including entry into raffles and other competitions.{" "}
            </div>
            <div>
              2. User Eligibility To use Wnnr.in, you must: - Be a resident of
              India. - Be at least 18 years old. - Possess a valid PAN card and
              Aadhar card for verification purposes.
            </div>{" "}
            <div>
              3. Acceptance of Terms By accessing and using Wnnr.in, you agree
              to comply with and be bound by these Terms and Conditions. If you
              do not agree with any part of these terms, you must not use the
              website.
            </div>
            <div>
              4. User Accounts - **Account Creation**: To participate in
              activities on Wnnr.in, you must create an account and provide
              accurate, complete information. - **Account Security**: You are
              responsible for maintaining the confidentiality of your account
              information and for all activities that occur under your account.
              - **Account Termination**: Wnnr.in reserves the right to terminate
              or suspend your account at any time for any reason, including
              violations of these terms.
            </div>
            <div>
              5. User Responsibilities - **Accurate Information**: Provide
              accurate and current information during registration and keep your
              profile information up to date. - **Lawful Use**: Use the platform
              only for lawful purposes and in accordance with these terms. -
              **Prohibited Activities**: Do not engage in fraudulent activities,
              use multiple accounts, or exploit the platform in any unauthorized
              manner.
            </div>
            <div>
              6. Intellectual Property - **Ownership**: All content, features,
              and functionality on Wnnr.in are the exclusive property of Two
              Trees Ventures Pvt Ltd or its licensors. - **Usage Rights**: Users
              are granted a limited, non-exclusive license to use the platform
              for personal, non-commercial use, subject to these terms.
            </div>
            <div>
              7. Disclaimer of Warranties Wnnr.in is provided on an "as is" and
              "as available" basis. We make no warranties or representations of
              any kind, express or implied, regarding the operation or
              availability of the website, or the information, content, or
              materials included on the website.
            </div>
            <div>
              8. Limitation of Liability In no event shall Wnnr.in, its
              directors, employees, or affiliates be liable for any direct,
              indirect, incidental, special, or consequential damages arising
              out of or in any way connected with the use of or inability to use
              the website or for any content obtained through the website.
            </div>
            <div>
              9. Indemnification You agree to indemnify, defend, and hold
              harmless Wnnr.in, its officers, directors, employees, and
              affiliates from any claims, liabilities, damages, losses, or
              expenses arising out of or in any way connected with your access
              to or use of the website.
            </div>
            <div>
              10. Governing Law (India) These terms and conditions shall be
              governed by and construed in accordance with the laws of India,
              without regard to its conflict of law principles.
            </div>
            <div>
              11. Dispute Resolution - **Informal Resolution**: In the event of
              any dispute, you agree to first attempt to resolve the dispute
              informally by contacting support@wnnr.in. - **Arbitration**: If
              the dispute cannot be resolved informally, it shall be resolved by
              binding arbitration in accordance with the Arbitration and
              Conciliation Act, 1996. The arbitration shall be conducted in
              Bangalore, India, and the language of arbitration shall be
              English.a By using Wnnr.in, you acknowledge that you have read,
              understood, and agree to be bound by these Terms and Conditions.
              For any questions or concerns, please contact support@wnnr.in
            </div>
          </div>
        </PopupWrapper>
      </PopUp>
    </FooterBox>
  );
};

export default Footer;
