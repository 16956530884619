import * as _ from "lodash";
import moment  from "moment";
import React, { useEffect, useState } from "react";
import Select, { OptionTypeBase } from "react-select";

import Img from "@assets/img";
import { Errors } from "@const/error";
import { Successes } from "@const/success";
import {
  GetSelectLuckyNumber,
  SaveSelectLuckyNumber,
} from "@network/Dashboard";
import { updateNotice } from "@store/noticeReducer";
import { SCREEN } from "@type/index";
import { useDispatch } from "react-redux";
import NumberList from "./NumberList";
import * as S from "./style";
import { Tickets } from "@network/Profile/type";

interface LuckyNumber {
  number: number;
  selected: boolean;
}

interface Ticket {
  id: number;
  value: number;
  label: string;
  expiredDate: string;
}

interface Range {
  start: number;
  end: number;
}

interface Props {
  raffleId?: number;
  onClose: () => void;
  tickets: Tickets[];
  availableTicketToday: number;
}

const SelectNumber = ({ raffleId, tickets, onClose, availableTicketToday }: Props) => {
  const [totalSel, setTotalSel] = useState<number>(0);
  const [selTickets, setSelTickets] = useState<number[]>([]);
  const [randomList, setRandomList] = useState<number[]>([]);
  const [numbers, setNumbers] = useState<number[]>([]);
  const [luckyNumbers, setLuckyNumbers] = useState<LuckyNumber[]>([]);
  const [secondNumbers, setSecondNumbers] = useState<LuckyNumber[]>([]);
  const [range, setRange] = useState<Range>({ start: 0, end: 99 });
  const [activeTab, setActiveTab] = useState<number>(1);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchLuckyNumber();
  }, []);

  const fetchLuckyNumber = async () => {
    if (!raffleId) return;
    const data = {
      raffleId: raffleId,
      screen: SCREEN.SELECT_LUCKY_NUMBER,
    };
    try {
      const res = await GetSelectLuckyNumber(data);

      if (res.status === "success") {
        setLuckyNumbers(res.data.luckyNumbers);
        setRange(res.data.range);
        setTotalSel(res.data.totalSelected);

        const rd = _.reduce(res.data.luckyNumbers, (data: number[], item) => {
          if (!item.selected) {
            data.push(item.number);
          }

          return data;
        }, []);
        setRandomList(rd)
      }
    } catch (err) {
      console.log(err);
      updateNotice(
        {
          type: "ERROR",
          content: Errors.UNKNOWN,
        },
        dispatch
      );
    }
  };

  const generateNextPage = () => {
    const list: LuckyNumber[] = [];

    for (let i = range?.end + 1; i <= range?.end + 100; i++) {
      list.push({
        number: i,
        selected: false,
      });
    }

    setSecondNumbers(list);
  };

  const handleChange = (selectedOptions: any) => {
    const selTickets = selectedOptions.map((option: Ticket) => option.id);

    setSelTickets(selTickets);

    if (selTickets.length < numbers.length) {
      setNumbers(numbers.slice(0, -1));
    }
  };

  const updateSelection = (number: number) => {
    let newNumbers: number[] = [];

    if (numbers.includes(number)) {
      newNumbers = numbers.filter((num) => num !== number);

      if (_.size(secondNumbers) > 0 && _.indexOf(randomList, number) !== -1) {
        newNumbers = _.intersection(newNumbers, randomList);
      }
    } else {
      const arr = _.difference(randomList, numbers);

      if (numbers.length >= selTickets.length || (_.size(arr) > 0  && _.indexOf(arr, number) === - 1)) {
        return;
      } else {
        newNumbers = [...numbers, number];
      }
    }

    setNumbers(newNumbers);
  };

  useEffect(() => {
    if ((_.size(numbers) - _.size(randomList) >= 0) && _.size(selTickets) > _.size(numbers)) {
      generateNextPage();
    } else if (_.size(randomList) - _.size(numbers) >= 0) {
      setSecondNumbers([]);
      setActiveTab(1);
    }
  }, [ numbers, selTickets ])

  const randomNumber = () => {
    let i = 0;
    let selecteds: number[] = [];

    if (100 - totalSel >= _.size(selTickets)) {
      selecteds = _.sampleSize(randomList, _.size(selTickets));
    } else {
      selecteds = [ ...randomList ];

      while (i < _.size(selTickets) - _.size(randomList)) {
        const r = _.random(range.end + 1, range.end + 100);
        selecteds.push(r);
        i++;
      }
    }

    setNumbers(selecteds);
  };

  const handleSelectTicket = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (!raffleId) return;
    const payload = {
      raffleId: raffleId,
      data: {
        luckyNumbers: numbers,
        ticketIds: selTickets,
      },
    };

    try {
      const res = await SaveSelectLuckyNumber(payload);
      if (res.status === "success") {
        onClose();
        updateNotice(
          {
            type: "SUCCESS",
            content: Successes.SAVE_SUCCESS,
          },
          dispatch
        );
      } else {
        updateNotice(
          {
            type: "ERROR",
            content: Errors[res.message]
              ? Errors[res.message]
              : Errors.SEND_OTP_ERROR,
          },
          dispatch
        );

        fetchLuckyNumber();
        setNumbers([]);
      }
    } catch (err) {
      console.log(err);
      updateNotice(
        {
          type: "ERROR",
          content: Errors.UNKNOWN,
        },
        dispatch
      );
    }
  };

   const MultiValueRemove = (props: any) => {
    return (
      <S.RemoveTag onClick={(e) => {
        props.innerProps.onClick();
        e.stopPropagation();
      }}>{'x'}</S.RemoveTag>
    );
  };

  const convertDate = (text: string) => moment(text).format('DD-MM-YYYY');

  const formatOptionLabel = (option: OptionTypeBase) => {
    return (
      <S.SelectBox>
        <div>{option.label}</div>
        <div style={{ fontSize: '0.8em', color: '#E6E6E6', fontWeight: 300 }}>Expiry Date: {convertDate((option as Ticket).expiredDate)}</div>
      </S.SelectBox>
    )
  };

  return (
    <S.Wrapper>
      <S.SelectDropdown>
        <h1>
          Select ticket <em className="red">*</em>
        </h1>
        <Select
          isMulti={true}
          name="ticket"
          options={tickets}
          onChange={(val) => handleChange(val)}
          isOptionDisabled={() => _.size(selTickets) >= availableTicketToday}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="Input ticket name"
          components={{ ClearIndicator: undefined, MultiValueRemove }}
          formatOptionLabel={formatOptionLabel}
        />
      </S.SelectDropdown>
      <S.TicketWrapper>
        <h1>Select Lucky Number</h1>
        {_.size(selTickets) > 0 ? (
          <S.SelectWrapper>
            <S.SelectOptions>
              <S.SelectOption>
                <img src={Img.SelectingIcon} />
                <h2>Selecting</h2>
              </S.SelectOption>
              <S.SelectOption>
                <img src={Img.UnavailableIcon} />
                <h2>Unavailable</h2>
              </S.SelectOption>
              <S.SelectOption>
                <img src={Img.AvailableIcon} />
                <h2>Available</h2>
              </S.SelectOption>
            </S.SelectOptions>
            {activeTab === 1 && (
              <NumberList
                numberList={luckyNumbers}
                selectedNumber={numbers}
                updateSelection={updateSelection}
              />
            )}
            {_.size(selTickets) > 0 && activeTab === 2 && (
              <NumberList
                numberList={secondNumbers}
                selectedNumber={numbers}
                updateSelection={updateSelection}
              />
            )}
            {
              <S.Note><span>*</span>You must select all the remaining numbers on the previous page, before you can select the numbers on the next page</S.Note>
            }
            { _.size(secondNumbers) > 0 && (
              <S.NavigationWrapper>
                <S.NavigationButton>
                  <S.Button onClick={() => setActiveTab(1)}>
                    <img src={Img.ChevronLeft} />
                  </S.Button>
                  <S.Button onClick={() => setActiveTab(2)}>
                    <img src={Img.ChevronRight} />
                  </S.Button>
                </S.NavigationButton>
              </S.NavigationWrapper>
            )}
            <S.Buttons>
              <button onClick={randomNumber} className="random-btn">
                Random number
              </button>
              <button
                onClick={(e) => handleSelectTicket(e)}
                className="select-btn"
                disabled={_.size(numbers) !== _.size(selTickets)}
              >{`Select (${_.size(numbers)}/${_.size(selTickets)})`}</button>
            </S.Buttons>
          </S.SelectWrapper>
        ) : (
          <S.NoSelection>
            <img src={Img.NoSelectionBg} />
            <h2>Please select ticket first to choose lucky number</h2>
          </S.NoSelection>
        )}
      </S.TicketWrapper>
    </S.Wrapper>
  );
};

export default SelectNumber;
